import rules from '@/utils/validate.js';

const getXbByidcard = (strCardId) => {
  let nSexNum = Number(strCardId.substr(-2, 1));
  if (nSexNum % 2 === 0) {
    return '2';
  } else {
    return '1';
  }
};
const dlrValue = [
  {
    title: '代理人类型',
    span: 12,
    type: 'dicSelect',
    key: 'dlrlx',
    props: {
      placeholder: '请选择代理人类型',
      type: '代理人类型',
    },
    rule: [rules.required],
    ifRender(object) {
      if (object.whetherDlr === '1') {
        if (object.dlrlx === '0') {
          object['dljg'] = '';
          object['dljgdh'] = '';
        }

        return true;
      } else {
        object.dlrlx = null;
        return false;
      }
    },
  },
  {
    title: '代理机构名称',
    span: 12,
    type: 'input',
    key: 'dljg',
    props: {
      placeholder: '请填写代理机构名称',
    },
    rule: [rules.required],
    ifRender(object) {
      return object.dlrlx === '1' && object.whetherDlr === '1';
    },
  },
  {
    title: '代理机构电话',
    span: 12,
    type: 'input',
    key: 'dljgdh',
    props: {
      placeholder: '请填写代理机构电话',
    },
    rule: [rules.required, rules.phone],
    ifRender(object) {
      return (object.dlrlx === '1' && object.whetherDlr === '1') || object.dljgdh;
    },
  },
  {
    title: '代理人名称',
    span: 12,
    type: 'input',
    key: 'dlrmc',
    props: {
      placeholder: '请填写代理人名称',
    },
    rule: [rules.required],
    ifRender(object) {
      return object.whetherDlr === '1' || object.dlrmc;
    },
  },
  {
    title: '代理人联系电话',
    span: 12,
    type: 'input',
    key: 'dlrdh',
    props: {
      placeholder: '请填写代理人联系电话',
    },
    rule: [rules.required, rules.phone],
    ifRender(object) {
      return object.whetherDlr === '1' || object.dlrdh;
    },
  },
  {
    title: '代理人证件种类',
    span: 12,
    type: 'dicSelect',
    key: 'dlrzjzl',
    props: {
      placeholder: '请选择代理人证件种类',
      type: '证件种类',
    },
    // value: '1',
    value: '',
    rule: [rules.required],
    ifRender(object) {
      return object.whetherDlr === '1' || object.dlrzjzl;
    },
  },
  {
    title: '代理人证件号码',
    span: 12,
    type: 'input',
    key: 'dlrzjh',
    props: {
      placeholder: '请填写代理人证件号码',
    },
    ruleFunction: (object) => {
      return [rules.required, rules.zjh(object.dlrzjzl)];
    },
    ifRender(object) {
      return object.whetherDlr === '1' || object.dlrzjh;
    },
  },
];
//权利人信息
//权利人名称
const qlrmc = {
  title: '名称',
  span: 12,
  type: 'input',
  key: 'qlrmc',
  props: {
    placeholder: '请填写名称',
  },
  rule: [rules.required],
};

//联系电话
const dh = {
  title: '联系电话',
  span: 12,
  type: 'input',
  key: 'dh',
  props: {
    placeholder: '请填写联系电话',
  },
  rule: [rules.required, rules.phone],
};

//证件种类
const zjzl = {
  title: '证件种类',
  span: 12,
  type: 'dicSelect',
  key: 'zjzl',
  props: {
    placeholder: '请选择证件种类',
    type: '证件种类',
  },
  rule: [rules.required],
};

//证件号
const zjh = {
  title: '证件号码',
  span: 12,
  type: 'input',
  key: 'zjh',
  props: {
    placeholder: '请填写证件号码',
  },
  input: (object) => {
    if (object['zjzl'] === '1' && object['zjh']) {
      object['xb'] = getXbByidcard(object['zjh']);
    }
  },
  ruleFunction: (object) => {
    return [rules.required, rules.zjh(object.zjzl)];
  },
};

//申请人类型 //1:个人 2: 企业 3: 事业机关
const qlrlx = {
  title: '申请人类型',
  span: 12,
  type: 'dicSelect',
  key: 'qlrlx',
  props: {
    placeholder: '请选择申请人类型',
    type: '申请人类型',
  },
  input: (object) => {
    switch (object['qlrlx']) {
      case '1':
        object['frdh'] = '';
        object['frmc'] = '';
        object['frzjh'] = '';
        //判断是否是身份证
        if (object['zjzl'] === '1' && object['zjh']) {
          object['xb'] = getXbByidcard(object['zjh']);
        }
        break;
      default:
        object['xb'] = '';
        break;
    }
  },
  rule: [rules.required],
};

//性别
const xb = {
  title: '性别',
  span: 12,
  type: 'radio',
  key: 'xb',
  props: {
    placeholder: '请选择性别',
    options: [
      {
        value: '1',
        label: '男',
      },
      {
        value: '2',
        label: '女',
      },
    ],
  },
  rule: [rules.required],
  ifRender(object) {
    return object.qlrlx === '1';
  },
};

//法定代表人名称
const frmc = {
  title: '法定代表人名称',
  span: 12,
  type: 'input',
  key: 'frmc',
  props: {
    placeholder: '请填写法定代表人名称',
  },
  rule: [rules.required, rules.mc],
  ifRender(object) {
    return object.qlrlx !== '1';
  },
};

//法定代表人电话
const frdh = {
  title: '法定代表人电话',
  span: 12,
  type: 'input',
  key: 'frdh',
  props: {
    placeholder: '请填写法定代表人电话',
  },
  rule: [rules.required, rules.phone],
  ifRender(object) {
    return object.qlrlx !== '1';
  },
};

//法人身份证号
const frzjh = {
  title: '法定代表人身份证号',
  span: 12,
  type: 'input',
  key: 'frzjh',
  props: {
    placeholder: '请填写法定代表人身份证号',
  },
  rule: [rules.required, rules.idcard],
  ifRender(object) {
    return object.qlrlx !== '1';
  },
};

//共有方式
const gyfs = {
  title: '共有方式',
  span: 12,
  type: 'dicSelect',
  key: 'gyfs',
  props: {
    placeholder: '请选择共有方式',
    type: '共有方式',
  },
  rule: [rules.required],
};

//权利比例
const qlbl = {
  title: '权利比例(%)',
  span: 12,
  type: 'input',
  key: 'qlbl',
  props: {
    placeholder: '请填写权利比例(%)',
  },
  rule: [rules.required, rules.zhengnumber, rules.min(0.01), rules.max(100)],
  ifRender(object) {
    return object.gyfs === '2';
  },
};

//是否持证人
const sfczr = {
  title: '是否持证人',
  span: 12,
  type: 'radio',
  key: 'sfczr',
  props: {
    placeholder: '请选择是否持证人',
    options: [
      {
        value: '1',
        label: '是',
      },
      {
        value: '0',
        label: '否',
      },
    ],
  },
  value: '1',
  rule: [rules.required],
};
//顺序号
const sxh = {
  title: '顺序号',
  span: 12,
  type: 'input',
  key: 'sxh',
  value: '',
  props: {
    placeholder: '请填写顺序号',
  },
  rule: [rules.required],
};

const jtfyts = {
  title: '家庭房屋套数',
  span: 12,
  type: 'input',
  key: 'houseNumber',
  props: {
    placeholder: '请填写家庭房屋套数',
  },
  rule: [rules.required, rules.number, rules.min(0)],
  ifRender(object) {
    return object.qlrlx === '1';
  },
};

//是否拥有代理人
const whetherDlr = {
  title: '是否拥有代理人',
  span: 12,
  type: 'radio',
  key: 'whetherDlr',
  props: {
    options: [
      {
        value: '1',
        label: '是',
      },
      {
        value: '2',
        label: '否',
      },
    ],
  },
  value: '2',
  change: (object) => {
    if (object['whetherDlr'] === '2') {
      object['dlrmc'] = '';
      object['dlrdh'] = '';
      object['dlrzjzl'] = '';
      object['dlrzjh'] = '';
      object['dljg'] = '';
      object['dljgdh'] = '';
    }
  },
  rule: [rules.required],
};
//婚姻状况
const marriageStatus = {
  title: '婚姻状况',
  span: 12,
  type: 'radio',
  key: 'marriageStatus',
  props: {
    options: [
      {
        value: '1',
        label: '未婚',
      },
      {
        value: '2',
        label: '已婚',
      },
      {
        value: '3',
        label: '涉外婚姻',
      },
    ],
  },
  value: '1',
  change: (object) => {},
  rule: [rules.required],
  ifRender(object) {
    return object.qlrlx == '1';
  },
};
//义务人名称
const ywrmc = {
  title: '名称',
  span: 12,
  type: 'input',
  key: 'ywrmc',
  props: {
    placeholder: '请填写名称',
  },
  rule: [rules.required],
};

//借款方式
const jkfs = {
  title: '借款方式',
  span: 12,
  type: 'dicSelect',
  key: 'gyfs',
  props: {
    placeholder: '请选择借款方式',
    type: '借款方式',
  },
  rule: [rules.required],
  input: (item) => {
    //弹窗为债务人 -- 抵押人共有方式为2，3，时，处理gyfs值为1
    if (item.gyfs === '1' || item.gyfs === '2' || item.gyfs === '3') {
      item.gyfs = '5';
    } else if (item.gyfs === '0') {
      item.gyfs = '4';
    }
  },
};

//权利人所属国家(地区)
const qlrgj = {
  title: '权利人所属国家',
  span: 12,
  type: 'dicSelect',
  key: 'qlrgj',
  props: {
    placeholder: '请选择权利人所属国家',
    type: '权利人所属国家',
  },
  rule: [rules.required],
  value: '142',
};

//义务人所属国家(地区)
const ywrgj = {
  title: '权利人所属国家',
  span: 12,
  type: 'dicSelect',
  key: 'ywrgj',
  props: {
    placeholder: '请选择权利人所属国家',
    type: '权利人所属国家',
  },
  rule: [rules.required],
  value: '142',
};

//权利人证件发证机关
const qlrzjfzjg = {
  title: '权利人证件发证机关',
  span: 12,
  type: 'input',
  key: 'qlrfzjg',
  props: {
    placeholder: '请填写权利人证件发证机关',
  },
  // rule: [rules.required]
};

//申请人通讯地址
const zl = {
  title: '通讯地址',
  span: 12,
  type: 'input',
  key: 'zl',
  props: {
    placeholder: '请填写通讯地址',
  },
  rule: [rules.required],
};

//发包方编码
const fbfbm = {
  title: '发包方编码',
  span: 12,
  type: 'input',
  key: 'fbfbm',
  props: {
    placeholder: '发包方编码',
  },
  rule: [rules.required],
};

//发包方编码
const rymc = {
  title: '人员名称',
  span: 12,
  type: 'input',
  key: 'rymc',
  props: {
    placeholder: '人员名称',
  },
  rule: [rules.required],
};

//地址
const dz = {
  title: '地址',
  span: 12,
  type: 'input',
  key: 'dz',
  props: {
    placeholder: '地址',
  },
};
const cbfbm = {
  title: '承包方编码',
  span: 12,
  type: 'input',
  key: 'cbfbm',
  props: {
    placeholder: '承包方编码',
  },
};
// 权利人默认值
// const qlrDialogValue = [qlrmc, dh, qlrgj, zjzl, zjh, qlrzjfzjg, qlrlx, xb, frmc, frdh, frzjh, gyfs, qlbl, sfczr, whetherDlr];
const qlrDialogValue = [
  qlrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  gyfs,
  qlbl,
  sfczr,
  whetherDlr,
  // marriageStatus,
];
//预告登记权利人
const ygdjQlrDialogValue = [
  qlrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  gyfs,
  qlbl,
  sfczr,
  whetherDlr,
  jtfyts,
  // marriageStatus,
];
// 义务人默认值
const ywrDialogValue = [
  ywrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  gyfs,
  qlbl,
  whetherDlr,
  // marriageStatus
];
// 债务人默认值
const zwrDialogValue = [
  ywrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  jkfs,
  qlbl,
  whetherDlr,
  // marriageStatus
];
// 房屋转移权利人
// const fwzyQlrDialogValue = [qlrmc, dh, zl, qlrgj, zjzl, zjh, qlrzjfzjg, qlrlx, xb, frmc, frdh, frzjh, gyfs, qlbl, sfczr, whetherDlr, jtfyts];
const fwzyQlrDialogValue = [
  qlrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  gyfs,
  qlbl,
  sfczr,

  whetherDlr,
  // marriageStatus,
];
// 房屋转移义务人
// const fwzyYwrDialogValue = [ywrmc, dh, zl, ywrgj, zjzl, zjh, qlrlx, xb, frmc, frdh, frzjh, gyfs, qlbl, whetherDlr, jtfyts];
const fwzyYwrDialogValue = [
  ywrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  gyfs,
  qlbl,
  whetherDlr,
  // marriageStatus
];
//抵押权人
// const dyqrDialogValue = [qlrmc, dh, qlrgj, zjzl, zjh, qlrlx, qlrzjfzjg, xb, frmc, frdh, frzjh, qlbl, whetherDlr];
const dyqrDialogValue = [
  qlrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  qlbl,
  gyfs,
  sfczr,
  whetherDlr,
  // marriageStatus,
];
// 发包方
const fbfDialogValue = [ywrmc, qlrlx, zjzl, zjh, dh, xb, frmc, frdh, frzjh, fbfbm, whetherDlr];
const cbfDialogValue = [qlrmc, qlrlx, zjzl, zjh, dh, xb, frmc, frdh, frzjh, cbfbm, whetherDlr];
// 林业经营者
const lyjyzDialogValue = [
  qlrmc,
  qlrlx,
  zjzl,
  zjh,
  dh,
  xb,
  frmc,
  frdh,
  frzjh,
  qlbl,
  gyfs,
  sfczr,
  whetherDlr,
  // marriageStatus,
];
//房屋转移权利人详情弹框
export const fwzyQlrDialogForm = [
  {
    value: fwzyQlrDialogValue.concat(dlrValue),
  },
];

//房屋转移义务人详情弹框
export const fwzyYwrDialogForm = [
  {
    value: fwzyYwrDialogValue.concat(dlrValue),
  },
];

//权利人详情弹框配置
export const qlrDialogForm = [
  {
    value: qlrDialogValue.concat(dlrValue),
  },
];
//预告登记权利人详情弹框配置
export const ygdjQlrDialogForm = [
  {
    value: ygdjQlrDialogValue.concat(dlrValue),
  },
];
//义务人详情弹框配置
export const ywrDialogForm = [
  {
    value: ywrDialogValue.concat(dlrValue),
  },
];
//债务人详情弹框配置
export const zwrDialogForm = [
  {
    value: zwrDialogValue.concat(dlrValue),
  },
];

//抵押权人详情弹框配置
export const dyqrDialogForm = [
  {
    value: dyqrDialogValue.concat(dlrValue),
  },
];
//发包方详情弹框配置
export const fbfDialogForm = [
  {
    value: fbfDialogValue.concat(dlrValue),
  },
];

//承包方详情弹框配置
export const cbfDialogForm = [
  {
    value: cbfDialogValue.concat(dlrValue),
  },
];
// 林业经营者详情弹框配置
export const lyjyzDialogForm = [
  {
    value: lyjyzDialogValue.concat(dlrValue),
  },
];
