//权利人表格配置
const qlrTable = [
  {
    label: '姓名',
    prop: 'qlrmc',
    width: '150px',
  },
  {
    label: '证件种类',
    prop: 'zjzl',
    type: 'dic',
  },
  {
    label: '证件号',
    prop: 'zjh',
    width: '200px',
  },
  {
    label: '联系电话',
    prop: 'dh',
  },
  {
    label: '共有方式',
    prop: 'gyfs',
    type: 'dic',
  },
  {
    label: '权利比例',
    prop: 'qlbl',
  },
];
//义务人表格配置
const ywrTable = [
  {
    label: '姓名',
    prop: 'ywrmc',
    width: '150px',
  },
  {
    label: '证件种类',
    prop: 'zjzl',
    type: 'dic',
  },
  {
    label: '证件号',
    prop: 'zjh',
    width: '200px',
  },
  {
    label: '联系电话',
    prop: 'dh',
  },
  {
    label: '共有方式',
    prop: 'gyfs',
    type: 'dic',
  },
  {
    label: '权利比例',
    prop: 'qlbl',
  },
];
//债务人表格配置
const zwrTable = [
  {
    label: '姓名',
    prop: 'ywrmc',
    width: '150px',
  },
  {
    label: '证件种类',
    prop: 'zjzl',
    type: 'dic',
  },
  {
    label: '证件号',
    prop: 'zjh',
    width: '200px',
  },
  {
    label: '联系电话',
    prop: 'dh',
  },
  {
    label: '借款方式',
    prop: 'jkfs',
    formatter: (row) => {
      if (row.gyfs === '0') {
        return `<span>单独借款</span>`;
      } else {
        return `<span>共同借款</span>`;
      }
    },
  },
  {
    label: '权利比例',
    prop: 'qlbl',
  },
];
// 权利信息表格配置
const qlxxTable = [
  {
    label: '不动产权证号',
    prop: 'bdcqzh',
  },
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '不动产面积t(㎡)',
    prop: 'fwcqmj',
  },
  {
    label: '房屋交易价格(万元)',
    prop: 'fwjyjg',
  },
];
//抵押首次不动产表格
const dyscqlxxTable = [
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '房屋产权面积(㎡)',
    prop: 'fwcqmj',
  },
];
//双预告不动产表格配置
const dyygdjqlxxTable = [
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },

  {
    label: '房屋产权面积(㎡)',
    prop: 'fwcqmj',
  },
];
//抵押预告登记不动产表格配置
const dyygdjqlxxExhTable = [
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },

  {
    label: '房屋产权面积(㎡)',
    prop: 'fwcqmj',
  },
];

// 权利信息表格配置
const ysqlxxTable = [
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '不动产面积(㎡)',
    prop: 'fwcqmj',
  },
  {
    label: '房屋交易价格(万元)',
    prop: 'fwjyjg',
  },
];
// 预告买卖流程，预告买卖注销、预告买卖+预告抵押流程“房产交易价格”改成“取得价格”
const ygqlxxTable = [
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '取得价格(万元)',
    prop: 'fwjyjg',
  },
];
//抵押首次不动产表格
const ygdjdyygdjqlxxTable = [
  {
    label: '不动产权证号',
    prop: 'bdcqzh',
  },
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '房屋产权面积(㎡)',
    prop: 'fwcqmj',
  },
];
// 权利信息表格配置
const fwscQlxxTable = [
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '不动产面积(㎡)',
    prop: 'jzmj', //暂与jzmj绑定
  },
];
// 权利信息表格配置(N200111，N100303)
const tddyqQlxxTable = [
  {
    label: '土地不动产权证号',
    prop: 'bdcqzh',
  },
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '不动产坐落',
    prop: 'zl',
    width: '250px',
  },
  {
    label: '土地使用权面积(㎡)',
    prop: 'tdsyqmj',
  },
];
//家庭组表格配置
const familyGroupTable = [
  {
    label: '姓名',
    prop: 'name',
  },
  {
    label: '家庭成员关系',
    prop: 'familyRelationshipEnum',
    type: 'dic',
  },
  {
    label: '证件种类',
    prop: 'zjzl',
    type: 'dic',
  },
  {
    label: '证件号码',
    prop: 'zjh',
    width: '200px',
  },
];
// 土地相关配置
// 发包方
const fbfTable = [
  {
    label: '发包方名称',
    prop: 'ywrmc',
  },
  {
    label: '发包方编码',
    prop: 'fbfbm',
  },
];
// 发包方代理人
const fbfdlrTable = [
  {
    label: '发包方负责人',
    prop: 'rymc',
  },
  {
    label: '发包方负责人证件种类',
    prop: 'zjzl',
  },
  {
    label: '发包方负责人电话',
    prop: 'dh',
  },
  {
    label: '发包方负责人地址',
    prop: 'dz',
  },
];
// 承包方
const cbfTable = [
  {
    label: '承包方名称',
    prop: 'qlrmc',
  },
  {
    label: '证件种类',
    prop: 'zjzl',
  },
  {
    label: '证件号',
    prop: 'zjh',
  },
  {
    label: '承包方编码',
    prop: 'cbfbm',
  },
  {
    label: '权利人类型',
    prop: 'qlrlx',
  },
];
// 土地基本信息
const tdqlxx = [
  {
    label: '权利性质',
    prop: 'qlxz',
  },
  {
    label: '不动产单元号',
    prop: 'bdcdyh',
  },
  {
    label: '合同代码',
    prop: 'cbhtbm',
  },
  {
    label: '承包期限（年）',
    prop: 'cbqx',
  },
  {
    label: '承包起始时间',
    prop: 'cbqssj',
  },
  {
    label: '承包结束时间',
    prop: 'cbjssj',
  },
  {
    label: '坐落',
    prop: 'zl',
  },
];
export default {
  qlrTable,
  ywrTable,
  zwrTable,
  qlxxTable,
  ysqlxxTable,
  dyscqlxxTable,
  dyygdjqlxxTable,
  ygdjdyygdjqlxxTable,
  fwscQlxxTable,
  familyGroupTable,
  dyygdjqlxxExhTable,

  tddyqQlxxTable,
  ygqlxxTable,
  fbfTable,
  fbfdlrTable,
  cbfTable,
  tdqlxx,
};
