import rules from '@/utils/validate.js';
const familyGroup = [
  {
    title: '家庭成员名称',
    span: 12,
    type: 'input',
    key: 'name',
    props: {
      placeholder: '请填写家庭成员名称'
    },
    rule: [rules.required]
  },
  {
    title: '家庭成员关系',
    span: 12,
    type: 'dicSelect',
    key: 'familyRelationshipEnum',
    props: {
      placeholder: '请选择家庭成员关系',
      type: '家庭成员关系'
    },
    rule: [rules.required]
  },
  {
    title: '证件种类',
    span: 12,
    type: 'dicSelect',
    key: 'zjzl',
    props: {
      placeholder: '请选择证件种类',
      type: '证件种类'
    },
    value: '1',
    rule: [rules.required]
  },
  {
    title: '证件号码',
    span: 12,
    type: 'input',
    key: 'zjh',
    props: {
      placeholder: '请填写代理人证件号码'
    },
    ruleFunction: (object) => {
      return [rules.required, rules.zjh(object.zjzl)];
    }
  }
];

//所有不动产表格详情弹框都使用的此配置
export const familyGroupColumn = [
  {
    value: familyGroup
  }
];
